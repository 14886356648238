import React from "react";
import { useState, useEffect } from "react";
import Logo from "../assets/images/logo-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { FaTimes } from '@fortawesome/free-brands-svg-icons';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleScroll = (e, targetId, targetPage) => {
        // Prevent default for same-page navigation only
        if (window.location.pathname === targetPage) {
            e.preventDefault(); // Prevent default link behavior
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Close menu if clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.menu-container') && !event.target.closest('.menu-icon')) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="bg-background_header text-primary_text shadow-md">
            <div className="container mx-auto flex justify-between items-center py-4">
                <div className="text-xl font-bold animate-slideInFromLeft">
                    <a href="/" className="hover:primary_text_hover ">
                        {/* Tibb */}
                        <img className="max-w-[150px] sm:max-w-[200px] p-6" src={Logo} alt="Tibb" />
                    </a>
                </div>
                <nav className="hidden md:block">
                    <ul className="flex space-x-6 animate-slideInFromRight">
                        <li>
                            <a
                                href="/"
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Home
                            </a>
                        </li>
                        <li>
                            <a
                                href="/#about-us"
                                onClick={(e) => handleScroll(e, "about-us", "/")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                About Us
                            </a>
                        </li>
                        <li>
                            <a
                                href="/#our-products"
                                onClick={(e) => handleScroll(e, "our-products", "/")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Products
                            </a>
                        </li>
                        <li>
                            <a
                                href="/#contact-us"
                                onClick={(e) => handleScroll(e, "contact-us", "/")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Contact Us
                            </a>
                        </li>
                        {/* <li>
                            <a
                                href="#our-services"
                                onClick={(e) => handleScroll(e, "our-services")}
                                className="p-4 transition ease-in-out hover:text-primary_text_hover duration-300 hover:underline"
                            >
                                Services
                            </a>
                        </li> */}
                    </ul>
                </nav>

                {/* Mobile Menu Icon */}
                <div className="md:hidden menu-icon m-2 p-2 text-3xl z-50 transition-transform animate-slideInFromRight" onClick={toggleMenu}>
                    {isMenuOpen ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} /> }
                </div>
            </div>

            {/* Sliding Mobile Menu */}
            <div
                className={`fixed mt-0 top-0 right-0 z-40 h-full w-64 bg-black shadow-lg transform ${
                    isMenuOpen ? "translate-x-0" : "translate-x-full"
                } transition-transform duration-300 ease-in-out menu-container`}
            >
                <ul className="mt-10 p-6 space-y-4">
                    <li>
                        <a
                            href="/"
                            onClick={() => setIsMenuOpen(false)}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Home
                        </a>
                    </li>
                    <li>
                        <a
                            href="/#about-us"
                            onClick={(e) => {
                                handleScroll(e, "about-us", "/");
                                setIsMenuOpen(false);
                            }}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            About Us
                        </a>
                    </li>
                    <li>
                        <a
                            href="/#our-products"
                            onClick={(e) => {
                                handleScroll(e, "our-products", "/");
                                setIsMenuOpen(false);
                            }}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Products
                        </a>
                    </li>
                    <li>
                        <a
                            href="/#contact-us"
                            onClick={(e) => {
                                handleScroll(e, "contact-us", "/");
                                setIsMenuOpen(false);
                            }}
                            className="block border-b-2 text-lg transition ease-in-out hover:text-primary_text_hover duration-300"
                        >
                            Contact Us
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    );
};

export default Header;
