import React from "react";
import { useState } from "react";
import FeatureImg1 from '../../assets/images/tibb-scribe-feature-2.png';
import FeatureImg2 from '../../assets/images/tibb-scribe-feature-2.png';
import FeatureImg3 from '../../assets/images/tibb-scribe-feature-2.png';
import FeatureImg4 from '../../assets/images/tibb-scribe-feature-2.png';
import FeatureImg5 from '../../assets/images/tibb-scribe-feature-5.png';
import FeatureImg6 from '../../assets/images/tibb-scribe-feature-6.png';
import FeatureImg7 from '../../assets/images/tibb-scribe-feature-7.png';
import FeatureImg8 from '../../assets/images/tibb-scribe-feature-8.png';
import FeatureImg9 from '../../assets/images/tibb-scribe-feature-9.png';

const featureImages = {
    "Real-time Transcription": FeatureImg1,
    "Time-saving Efficiency": FeatureImg2,
    "Customizable Templates": FeatureImg3,
    "HIPAA-compliant": FeatureImg4,
    "AI-powered Coding": FeatureImg5,
    "Data Encryption": FeatureImg6,
    "Role-Based Access Control": FeatureImg7,
    "Audit Trails": FeatureImg8,
    "EMR/EHR Integration": FeatureImg9,
};

const KeyFeatures = () => {
    const [activeFeature, setActiveFeature] = useState("Real-time Transcription");
    const [hovering, setHovering] = useState(false);

    return (
        <div className="bg-white p-4 pb-12 text-black">
            <section id="key-features" className="flex flex-col items-center justify-between p-4 md:p-8 text-black animate-slideInFromAbove">
                <h1 className="text-2xl md:text-4xl font-bold animate-slideInFromAbove">
                    Key <strong className="text-gradient-ai">Features</strong>
                </h1>
            </section>

            <section className="flex flex-row md:flex-col items-center justify-between p-8 bg-bgFeatures rounded-xl text-black max-w-6xl mx-auto">
                <div className="flex flex-col md:flex-row items-center justify-between h-full">
                    <div className="md:w-2/5 h-auto">
                        <img
                            src={featureImages[activeFeature]}
                            className={`w-2/4 md:w-auto h-auto mx-auto rounded-lg animate-slideInFromLeft ${ hovering ? 'animate-spinLeft' : 'animate-spinRight' }`}
                            // className={`w-3.25/5 h-auto mx-auto rounded-lg animate-slideInFromLeft ${ hovering ? 'animate-spinLeft' : 'animate-spinRight' }`}
                            alt={activeFeature}
                        />
                    </div>

                    <div className="w-full md:w-3/5 mb-4 text-center md:text-left pl-5 max-h-full overflow-y-auto custom-scrollbar">
                        <div className="p-3 rounded-2xl md:rounded-r-3xl md:my-2 hover:bg-bgBlackLevel1x transition" onMouseEnter={() => {setActiveFeature("EMR/EHR Integration"); setHovering(true)}} onMouseLeave={() => {setActiveFeature("Real-time Transcription"); setHovering(false) }}>
                            <h1 className="text-lg md:text-xl font-bold mb-2">
                                <strong className="text-gradient-ai">EMR/EHR</strong> Integration
                            </h1>
                            <p className="text-sm md:text-base text-black">
                                We provide seamless integrations with your own EMR/EHR.
                            </p>
                        </div>
                        <div className="p-3 rounded-2xl md:rounded-r-3xl md:my-2 hover:bg-bgBlackLevel1x transition" onMouseEnter={() => {setActiveFeature("Real-time Transcription"); setHovering(true)}} onMouseLeave={() => {setActiveFeature("Real-time Transcription"); setHovering(false) }}>
                            <h1 className="text-lg md:text-xl font-bold mb-2">
                                <strong className="text-gradient-ai">Real-time</strong> Transcription
                            </h1>
                            <p className="text-sm md:text-base text-black">
                                Tibb's AI Scribe records provider-patient conversations and transcribes 
                                them instantly into customizable formats, such as SOAP notes or clinical notes.
                            </p>
                        </div>
                        {/* <div className="mb-4 p-3 rounded-r-3xl hover:bg-bgWhiteLevel1 transition" onMouseEnter={() => setActiveFeature("Time-saving Efficiency")} onMouseLeave={() => setActiveFeature("Real-time Transcription")}>
                            <h1 className="text-xl font-bold mb-2">
                                Time-saving <strong className="text-gradient-ai">Efficiency</strong>
                            </h1>
                            <p className="text-base text-primary_text">
                                By automating the documentation process, Tibb's AI Scribe significantly 
                                reduces the time providers spend on chart notes, freeing up valuable time 
                                for meaningful interactions with patients.
                            </p>
                        </div> */}
                        {/* <div className="mb-4 p-3 rounded-r-3xl hover:bg-bgWhiteLevel1 transition" onMouseEnter={() => setActiveFeature("Customizable Templates")} onMouseLeave={() => setActiveFeature("Real-time Transcription")}>
                            <h1 className="text-xl font-bold mb-2">
                                <strong className="text-gradient-ai">Customizable</strong> Templates
                            </h1>
                            <p className="text-base text-primary_text">
                                Tibb's AI Scribe offers a range of pre-designed templates that can be 
                                tailored to suit the specific needs of each practice or provider.
                            </p>
                        </div> */}
                        {/* <div className="mb-4 p-3 rounded-r-3xl hover:bg-bgWhiteLevel1 transition" onMouseEnter={() => {setActiveFeature("HIPAA-compliant"); setHovering(true)}} onMouseLeave={() => {setActiveFeature("Real-time Transcription"); setHovering(false) }}>
                            <h1 className="text-xl font-bold mb-2">
                                Secure and <strong className="text-gradient-ai">HIPAA-compliant</strong>
                            </h1>
                            <p className="text-base text-primary_text">
                                Tibb's AI Scribe is designed with patient privacy and security in 
                                mind, adhering to strict HIPAA guidelines to protect sensitive 
                                patient information.
                            </p>
                        </div> */}
                        <div className="p-3 rounded-2xl md:rounded-r-3xl md:my-2 hover:bg-bgBlackLevel1x transition" onMouseEnter={() => {setActiveFeature("AI-powered Coding"); setHovering(true)}} onMouseLeave={() => {setActiveFeature("Real-time Transcription"); setHovering(false) }}>
                            <h1 className="text-lg md:text-xl font-bold mb-2">
                                <strong className="text-gradient-ai">AI-powered</strong> Coding
                            </h1>
                            <p className="text-sm md:text-base text-black">
                                Once the chart note is completed, providers can utilize built-in 
                                AI tools to automatically extract relevant CPT codes, ensuring 
                                accurate billing and reimbursement.
                            </p>
                        </div>
                        <div className="p-3 rounded-2xl md:rounded-r-3xl md:my-2 hover:bg-bgBlackLevel1x transition" onMouseEnter={() => {setActiveFeature("Data Encryption"); setHovering(true)}} onMouseLeave={() => {setActiveFeature("Real-time Transcription"); setHovering(false) }}>
                            <h1 className="text-lg md:text-xl font-bold mb-2">
                                <strong className="text-gradient-ai">Security</strong>
                            </h1>
                            <p className="text-sm md:text-base text-black">
                                All patient data is encrypted during transmission and storage. This 
                                ensures that even if unauthorized access occurs, the information 
                                remains protected. We also have integrated PII Redaction.
                            </p>
                        </div>
                        {/* <div className="mb-4 p-3 rounded-r-3xl hover:bg-bgWhiteLevel1 transition" onMouseEnter={() => setActiveFeature("Role-Based Access Control")} onMouseLeave={() => setActiveFeature("Real-time Transcription")}>
                            <h1 className="text-xl font-bold mb-2">
                                <strong className="text-gradient-ai">Role-Based</strong> Access Control (RBAC)
                            </h1>
                            <p className="text-base text-primary_text">
                                Tibb's AI Scribe restricts access to patient records based on user roles. 
                                Only authorized healthcare professionals can view or edit patient data. 
                                For example, doctors may have full access, while administrative staff may 
                                have limited access.
                            </p>
                        </div> */}
                        <div className="p-3 rounded-2xl md:rounded-r-3xl md:my-2 hover:bg-bgBlackLevel1x transition" onMouseEnter={() => {setActiveFeature("Audit Trails"); setHovering(true)}} onMouseLeave={() => {setActiveFeature("Real-time Transcription"); setHovering(false) }}>
                            <h1 className="text-lg md:text-xl font-bold mb-2">
                                Audit <strong className="text-gradient-ai">Trails</strong>
                            </h1>
                            <p className="text-sm md:text-base text-black">
                                Tibb's AI Scribe maintains detailed logs of who accessed patient records, when, 
                                and what changes were made. This audit trail helps track any unauthorized 
                                access or suspicious activity.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default KeyFeatures;