import React from "react";
// import AboutUsImg from '../assets/images/about-us-2.png';
import EffectVideo from '../assets/videos/effect.mp4';
// import { useInView } from 'react-intersection-observer';

const AboutUs = () => {
    // const { ref: headingRef, inView: headingInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    // const { ref: videoRef, inView: videoInView } = useInView({ triggerOnce: true, threshold: 0.1 });
    // const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true, threshold: 0.1 });

    return (
        <>
            {/* <section id="about-us" className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-background_main text-primary_text"> */}
                {/* <h1 className="text-3xl font-bold mb-4"><strong className="text-text_strong">
                    About</strong> Us
                </h1> */}

                {/* <div className="max-w-3xl my-5 text-center space-y-4"> */}
                    {/* <p className="text-lg text-primary_text">
                        We are a team of passionate professionals dedicated to transforming the
                        landscape of healthcare. Our mission is to provide innovative solutions
                        that bring the benefits of modern technology to the forefront of patient
                        care.
                    </p>
                    <p className="text-lg text-primary_text">
                        At Tibb, we specialize in developing AI-powered healthcare applications
                        that address the specific needs of mental health care. Our focus is on
                        making electronic medical records (EMR) smarter, more efficient, and
                        better tailored to support clinicians and patients alike.
                    </p>
                    <p className="text-lg text-primary_text">
                        Our commitment is to bridge the gap between technology and healthcare by
                        delivering solutions that are not only functional but also user-friendly,
                        ensuring a seamless experience for all our users.
                    </p> */}
                    {/* <p className="text-lg text-primary_text">
                        Tibb is founded with a vision to fill the gaps in healthcare by 
                        utilizing the advantages offered through AI, one solution at a time. 
                        We take healthcare personally and are making it personal rather 
                        than one size fits all. We are developing the tools and giving 
                        them in the hands of both Physicians and patients.
                    </p>
                    <p className="text-lg text-primary_text">
                        We have mobilized a team of industry veterans both from Healthcare 
                        and Technology, dedicated to transforming the landscape of healthcare. 
                        Our goal is to provide advanced, evidence based and comprehensive care 
                        easily accessible to humanity in every part of the world.
                    </p> */}
                {/* </div> */}
            {/* </section> */}

            <section id="about-us" className="w-full m-8"></section>
            
            <section className="flex flex-row md:flex-row items-center justify-between p-8 m-8 h-screen bg-background_main text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    {/* Video on the left */}
                    <div className="w-full md:w-1/2 mb-4 md:mb-0 animte-slideInFromLeft hidden md:block">
                        <video
                            src={EffectVideo}
                            className="w-[250px] lg:w-[375px] h-auto mx-auto rounded-lg shadow-lg"
                            autoPlay
                            loop
                            muted
                        />
                    </div>

                    {/* text on the rigth */}
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <div className="animate-slideInFromAbove">
                                <h1 className="text-2xl sm:text-3xl font-bold mb-4">
                            <strong className="text-gradient-ai">About</strong> Us
                        </h1>
                        </div>
                        <div className="text-sm sm:text-lg animate-slideInFromRight">
                            <p className="text-primary_text">
                                Tibb is founded with a vision to fill the gaps in healthcare by 
                                utilizing the advantages offered through AI, one solution at a time. 
                                We take healthcare personally and are making it personal rather 
                                than one size fits all. We are developing the tools and giving 
                                them in the hands of both Physicians and patients.
                            </p>
                            <p className="text-primary_text">
                                We have mobilized a team of industry veterans both from Healthcare 
                                and Technology, dedicated to transforming the landscape of healthcare. 
                                Our goal is to provide advanced, evidence based and comprehensive care 
                                easily accessible to humanity in every part of the world.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AboutUs;
