import React from "react";
import { Routes, Route } from "react-router-dom";
import TibbScribe from "./TibbScribe";
import CommingSoon from "./CommingSoon";

const ProductPageCheck = () => {
    return (
        <>
            <h1 className="text-white">This is Product page!</h1>
        </>
    );
}

const Product = () => {
    return (
        <>
            {/* <h1 className="text-white">Product page!</h1> */}

            {/* Nested Routes */}
            <Routes>
                <Route path="tibb-scribe" element={<TibbScribe />} />
                <Route path="tibb-360" element={<CommingSoon />} />
                <Route path="tibb-360-doc" element={<CommingSoon />} />
                <Route path="tibb-spot" element={<CommingSoon />} />
                <Route path="*" element={<ProductPageCheck    />} />
            </Routes>
        </>
    );
};

export default Product;