import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Logo from "../../assets/images/logo_tibb_scribe_2.png";
import Image2 from '../../assets/images/tibb-scribe-image-10.png';

const HeroScribe = () => {
    const handleScroll = (e, targetId) => {
        e.preventDefault(); // Prevent default link behavior
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <section className="flex flex-row md:flex-col items-center justify-between h-screen p-4 m-0 mb-10 md:p-6 md:m-2 xxl:m-8 rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-around space-x-4">
                    {/* text on the left */}
                    <div className="w-full md:w-1/2 p-6 rounded-3xl text-center md:text-left">
                        <div className="flex flex-col w-full items-center justify-start">
                            <div className="flex w-full md:w-4/5 m-4 items-start animate-slideInFromLeft_F">
                                <img
                                    src={Logo}
                                    className="w-full md:w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                                    alt="Tibb Scribe Logo main"
                                />
                            </div>
                            <div className="flex w-full m-4 items-center text-center justify-center animate-slideInFromLeft">
                                <h1 className="text-xl md:text-3xl text-golden">Focus on Care, Not Charts!</h1>
                            </div>
                            <div className="flex w-full m-2 items-center text-center justify-center animate-slideInFromLeft">
                                <h1 className="text-sm md:text-lg">Let AI Automate Your Notes, Elevate Patient Care <br /> — Secure & HIPAA Compliant.</h1>
                            </div>
                            <div className="flex w-full m-2 justify-center text-2xl text-black animate-slideInFromLeft">
                                <button
                                    type="button"
                                    onClick={(e) => handleScroll(e, "why-tibb")}
                                    className="button-gradient-ai black"
                                >
                                    Explore Now!
                                </button>
                            </div>
                            <div className="flex w-full m-4 justify-center space-x-4 text-2xl text-white animate-slideInFromRight">
                                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-instaPurple" icon={faInstagram} /></a>
                                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-facebookBlue" icon={faFacebook} /></a>
                                <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-twitterBlue" icon={faTwitter} /></a>
                                <a href="mailto:contact@tibb.io" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-bgWhiteLevel7" icon={faEnvelope} /></a>
                            </div>
                        </div>
                    </div>
                    
                    {/* image on the right */}
                    <div className="w-full md:w-1/2 md:text-right pl-5 animate-slideInFromRight hidden md:block">
                        <img
                            src={Image2}
                            className="w-4/5 h-auto mx-auto rounded-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section>
        </>
    );
};

export default HeroScribe;
