import React from "react";
import { useState } from "react";
// import SignUpPopUp from "./SignUpPopUp";
import SignUpPopUp2 from "./SignUpPopUp2";

const SubscribeNow = () => {
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);

    const handleButtonClick = () => {
        setIsPopUpVisible(true);
    };

    const handleClosePopUp = () => {
        setIsPopUpVisible(false);
    };

    return (
        <>
            <section id="subscribe-now" className="flex flex-col items-center justify-between p-4 m-4 md:p-8 md:m-8 text-primary_text">
                <h1 className="text-2xl md:text-4xl font-bold text-center mb-0 animate-slideInFromAbove">
                    <strong className="text-gradient-ai">Subscribe</strong> Now
                </h1>
            </section>

            <section className="flex flex-col md:flex-row items-center justify-between p-0 m-4 md:m-8 gap-4 md:rounded-xl text-primary_text">
                <div className="flex items-center justify-center md:p-10 w-full md:w-1/3 animate-spinLeft">
                    {/* <h1>Pricing Plan 1 - 10 sessions free</h1> */}
                    <div className="flex flex-col items-center justify-between w-full h-[325px] md:h-[375px] p-4 bg-white rounded-2xl md:rounded-3xl text-black cursor-pointer md:hover:scale-105 transition">
                        <div className="flex flex-col items-center space-y-3 justify-between">
                            <h1 className="text-2xl md:text-3xl font-bold">Plan 1</h1>
                            <h1 className="background-gradient-ai_1 w-[120px] md:w-[150px] h-auto md:h-[50px] text-ld md:text-2xl font-light rounded-full">Free</h1>
                            <p className="text-white">*</p>
                        </div>
                        <hr className="w-full border border-bgBlackLevel1 border-1 rounded-full" />
                        <div className="flex flex-col w-full h-3/5 items-center space-y-2 justify-around">
                            <ul className="list-disc list-inside text-left space-y-1">
                                <li>10 Free Sessions</li>
                                <li>Access to full suite of features</li>
                            </ul>
                            <button
                                type="button"
                                onClick={handleButtonClick}
                                className="button-gradient-ai_2"
                            >
                                SignUp Now!
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center md:p-10 w-full md:w-1/3 animate-spinLeft">
                    {/* <h1>Pricing Plan 2 - $50/month per practitioner - charged anually</h1> */}
                    <div className="flex flex-col items-center justify-between w-full h-[325px] md:h-[375px] p-4 bg-white rounded-2xl md:rounded-3xl text-black cursor-pointer md:hover:scale-105 transition">
                        <div className="flex flex-col items-center space-y-3 justify-between">
                            <h1 className="text-2xl md:text-3xl font-bold">Plan 2</h1>
                            <h1 className="background-gradient-ai_1 w-[120px] md:w-[150px] h-auto md:h-[50px] text-ld md:text-2xl font-light rounded-full">$50/Month</h1>
                            <p className="">*Billed Anually</p>
                        </div>
                        <hr className="w-full border border-bgBlackLevel1  border-1 rounded-full" />
                        <div className="flex flex-col w-full h-3/5 items-center space-y-2 justify-around">
                            <ul className="list-disc list-inside text-left space-y-1">
                                <li>Unlimited Sessions</li>
                                <li>Integration with your EMR/EHR</li>
                                <li>Access to all features available</li>
                            </ul>
                            <button
                                type="button"
                                onClick={handleButtonClick}
                                className="button-gradient-ai_2"
                            >
                                SignUp Now!
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center md:p-10 w-full md:w-1/3 animate-spinLeft">
                    {/* <h1>Pricing Plan 3 - Enterprise plan (for enterprises having more that 10 practitioners) - Contact us for customized pricing</h1> */}
                    {/* <h1>Pricing Plan 3 - Tibb Scribe for mental Health - $99/Month per Clinician - Charged Anually</h1> */}
                    <div className="flex flex-col items-center justify-between w-full h-[325px] md:h-[375px] p-4 bg-white rounded-2xl md:rounded-3xl text-black cursor-pointer md:hover:scale-105 transition">
                        <div className="flex flex-col items-center space-y-3 justify-between">
                            <h1 className="text-2xl md:text-3xl font-bold">Plan 3</h1>
                            <h1 className="background-gradient-ai_1 w-[120px] md:w-[150px] h-auto md:h-[50px] text-ld md:text-2xl font-light rounded-full">$99/Month</h1>
                            <p className="">*Billed Anually</p>
                        </div>
                        <hr className="w-full border border-bgBlackLevel1  border-1 rounded-full" />
                        <div className="flex flex-col w-full h-3/5 items-center space-y-2 justify-around">
                            <ul className="list-disc list-inside text-left space-y-1">
                                <li>Unlimited Sessions</li>
                                <li>Integration with your EMR/EHR</li>
                                <li>Access to all features available</li>
                                <li>Specialized for Mental Health</li>
                            </ul>
                            <button
                                type="button"
                                onClick={handleButtonClick}
                                className="button-gradient-ai_2"
                            >
                                SignUp Now!
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="flex flex-col md:flex-row items-center justify-between p-0 m-8 gap-4 rounded-xl text-primary_text">
                <div className="flex items-center justify-center p-10 w-full">
                    <stripe-pricing-table pricing-table-id="prctbl_1QHPswD6Q7UPopuzmDAde006"
                    publishable-key="pk_test_51QHNm7D6Q7UPopuzl5Qo6jdOxq3a7QekoHIsDjSNJ3Fi8dFl6Tj38Lz81Et46Ac76dqJWaby6ADBceEz2csZPMCD00sFWbyCz1">
                    </stripe-pricing-table>
                </div>
            </section> */}

            {/* <SignUpPopUp show={isPopUpVisible} onClose={handleClosePopUp} /> */}
            <SignUpPopUp2 show={isPopUpVisible} onClose={handleClosePopUp} />
        </>
    );
};

export default SubscribeNow;
