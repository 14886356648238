import React from "react";
import Logo from '../assets/images/logo-2.png'

const Footer = () => {
    return (
        <footer className="text-primary_text">
            <div className="container mx-auto pt-0 py-8">
                <hr className="my-4 border-grey_level_5" />
                <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="text-xl font-bold">
                        <a href="/" className="hover:primary_text_hover">
                            {/* Tibb */}
                            <img className="max-w-[150px] sm:max-w-[200px] p-2 sm:p-0" src={Logo} alt="Tibb" />
                        </a>
                    </div>
                    <nav className="mt-6 md:mt-0">
                        <ul className="flex flex-col items-center md:items-end space-y-2">
                            {/* Navigation URLs here */}
                            <li>
                                <a
                                    href="/privacy-policy"
                                    className="p-4 hover:text-primary_text_hover hover:underline"
                                >
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a
                                    href="/terms-and-conditions"
                                    className="p-4 hover:text-primary_text_hover hover:underline"
                                >
                                    Terms & Conditions
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
                {/* <div className="flex flex-col md:flex-row justify-between items-center text-sm my-8"> */}
                    {/* <div>
                        <a
                            href="/privacy-policy"
                            className="p-4 hover:text-primary_text_hover hover:underline"
                        >
                            Privacy Policy
                        </a>
                    </div>
                    <div>
                        <a
                            href="/terms-and-conditions"
                            className="p-4 hover:text-primary_text_hover hover:underline"
                        >
                            Terms & Conditions
                        </a>
                    </div> */}
                    {/* <div>
                        <a
                            href="/baa"
                            className="p-4 hover:text-primary_text_hover hover:underline"
                        >
                            BAA
                        </a>
                    </div> */}
                {/* </div> */}
            </div>
        </footer>
    );
};

export default Footer;
