import React from "react";
import HeroImg from '../assets/images/hero.png';

const Hero = () => {
    return (
        <section className="flex flex-col md:flex-row items-center justify-between md:h-screen p-8 md:mx-8 md:mt-[-50px] md:p-4 xxl:m-0 bg-background_main text-primary_text">
            <div className="w-full md:w-1/2 md:mb-0 block md:hidden animate-slideInFromRight_F">
                <img
                    src={HeroImg}
                    alt="Tibb AI"
                    className="w-[450px] h-auto mx-auto rounded-lg shadow-lg"
                />
            </div>
            {/* Tagline on the left */}
            <div className="w-full md:w-1/2 text-center md:text-left animate-slideInFromLeft_F">
                <h2 className="text-2xl sm:text-3xl font-bold mb-4">
                    <strong className="text-gradient-ai">Digitizing</strong> Healthcare with <span className="text-gradient-ai">AI</span>
                </h2>

                {/* <div className="w-full md:w-1/2 md:mb-0 block md:hidden animate-slideInFromRight_F">
                    <img
                        src={HeroImg}
                        alt="Tibb AI"
                        className="w-[450px] h-auto mx-auto rounded-lg shadow-lg"
                    />
                </div> */}
                {/* <p className="text-lg text-primary_text">
                    At Tibb we are developing modern healthcare applications and infusing them with AI - A New generation of <strong className="text-text_strong">EMR</strong> specifically to cater to  <strong className="text-text_strong">Mental Health</strong> needs.
                </p> */}
                <p className="text-sm sm:text-lg text-primary_text">
                    At Tibb we are developing modern <strong  className="text-text_strong">healthcare</strong> applications 
                    and infusing them with <strong className="text-text_strong">AI</strong> - A New generation of solutions that are not only functional 
                    but also <strong className="text-text_strong">user-friendly</strong>, ensuring a seamless experience.
                </p>
            </div>
            {/* Image on the right */}
            <div className="w-full md:w-1/2 hidden md:block animate-slideInFromRight_F">
                <img
                    src={HeroImg}
                    alt="Tibb AI"
                    className="w-[450px] h-auto mx-auto rounded-lg shadow-lg"
                />
            </div>
        </section>
    );
};

export default Hero;
