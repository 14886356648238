import React, { useState } from "react";
// import ContactUsImg from '../assets/images/contact-us-2.gif';
import ContactUsVideo from '../assets/videos/contact-us.mp4';

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    // const serverUrl = "http://localhost:5000/contact"; // Change to your server's endpoint

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Please enter a valid email address";
        }
        if (!formData.subject) {
            newErrors.subject = "Subject is required";
        }
        if (!formData.message) {
            newErrors.message = "Message is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validate()) return;

        alert("Message sent!");

        // // Submit form data to server
        // fetch(serverUrl, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify(formData),
        // })
        //     .then((response) => {
        //         if (response.ok) {
        //             alert("Message sent successfully!");
        //             setFormData({ email: "", subject: "", message: "" });
        //         } else {
        //             alert("Failed to send message");
        //         }
        //     })
        //     .catch((error) => console.error("Error:", error));
    };

    return (
        <section id="contact-us" className="flex flex-row md:flex-col h-screen items-center justify-between py-8 m-8 mb-0 bg-background_main text-primary_text">
            <h1 className="text-3xl font-bold mb-4 animate-slideInFromAbove hidden md:block">
                <strong className="text-gradient-ai">Contact</strong> Us
            </h1>

            <div className="flex flex-col md:flex-row items-center justify-between w-full">
                <div className="w-full md:w-1/2 mb-4 md:mb-0 animate-slideInFromLeft hidden md:block">
                    {/* <img 
                        src={ContactUsImg}
                        className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                        alt="Contact Tibb Team"
                    /> */}
                    <video
                        src={ContactUsVideo}
                        className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                        autoPlay
                        loop
                        muted
                    />
                </div>
                <div className="w-full md:w-1/2 text-center md:text-left animate-slideInFromRight">
                    {/* <p className="text-white">Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text</p> */}
                    <h1 className="text-3xl text-center block md:hidden font-bold mb-4 animate-slideInFromAbove">
                        <strong className="text-gradient-ai">Contact</strong> Us
                    </h1>
                    <div className="w-full my-1 text-center">
                        <form onSubmit={handleSubmit} className="space-y-6 p-4 rounded-lg md:px-8">
                            {/* Name Field */}
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="*Your Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="w-full p-3 rounded-lg bg-white text-black placeholder-text-gradient-ai"
                                />
                                {errors.name && <p className="text-textError text-sm mt-1">{"*" + errors.name}</p>}
                            </div>

                            {/* Email Field */}
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="*Your Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full p-3 rounded-lg bg-white text-black"
                                />
                                {errors.email && <p className="text-textError text-sm mt-1">{"*" + errors.email}</p>}
                            </div>

                            {/* Subject Field */}
                            <div className="relative">
                                <div className={`rounded-lg ${errors.subject ? 'border-borderError border' : 'border-white border'}`}>
                                    <input
                                        type="text"
                                        name="subject"
                                        placeholder="*Subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className={`w-full p-3 rounded-lg bg-white text-black focus:outline-none ${errors.subject ? 'shadow-[inset_0_0_0_1px_red]' : ''}`}
                                    />
                                    {errors.subject && (
                                        <p className="absolute text-xs text-textError top-1 right-3">
                                            *{errors.subject}
                                        </p>
                                    )}
                                </div>
                            </div>

                            {/* Message Field */}
                            <div className="relative">
                                <div className={`rounded-lg ${errors.subject ? 'border-borderError border' : 'border-white border'}`}>
                                    <input
                                        name="text"
                                        placeholder="*Your Phone Number"
                                        rows="5"
                                        value={formData.phoneNumber}
                                        onChange={handleChange}
                                        className="w-full p-3 rounded-lg bg-white text-black"
                                    />
                                    {errors.message && <p className="text-textError text-sm mt-1">{"*" + errors.message}</p>}
                                </div>
                            </div>

                            {/* Message Field */}
                            <div>
                                <textarea
                                    name="message"
                                    placeholder="*Your Message"
                                    rows="5"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="w-full p-3 rounded-lg bg-white text-black"
                                ></textarea>
                                {errors.message && <p className="text-textError text-sm mt-1">{"*" + errors.message}</p>}
                            </div>

                            {/* Submit Button */}
                            <div className="flex justify-center">
                                <button
                                    type="submit"
                                    className="button-gradient-ai"
                                >
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;
