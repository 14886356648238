import React from "react";
import WhyTibbImage from '../../assets/images/Why_Tibb_Tibb_theme-2.png';
import WhyTibbImageMob from '../../assets/images/why-tibb-mob.png';
import WhyTibbImageMob1 from '../../assets/images/why-tibb-mob-1.png';
import WhyTibbImageMob2 from '../../assets/images/why-tibb-mob-2.png';
import WhyTibbImageMob3 from '../../assets/images/why-tibb-mob-3.png';
import WhyTibbImageMob4 from '../../assets/images/why-tibb-mob-4.png';

const WhyTibb = () => {
    return (
        <div className="h-screen">
            <section id="why-tibb" className="flex flex-col items-center justify-between py-8 md:p-8 xxl:mt-[50px] text-primary_text animate-slideInFromAbove">
                <h1 className="text-2xl md:text-4xl font-bold xxl:mt-[50px] animate-slideInFromAbove">
                    Why <strong className="text-gradient-ai">Tibb</strong>
                </h1>
            </section>

            <section className="flex flex-row md:flex-col items-center justify-between px-2 pb-8 md:p-8 xxl:mb-[120px] rounded-xl text-primary_text max-w-6xl mx-auto">
                <div className="flex items-center justify-between h-full hidden md:block">
                    <div className="max-w-full h-auto">
                        <img
                            src={WhyTibbImage}
                            className="w-full h-auto mx-auto rounded-lg animate-slideInFromBelow"
                            alt="whyTibb"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between h-full block mb-12 md:hidden">
                    <div className="max-w-full h-auto mx-8">
                        <img
                            src={WhyTibbImageMob}
                            className="w-full h-auto mx-auto rounded-lg animate-slideInFromRight"
                            alt="whyTibb"
                        />
                    </div>

                    <div className="flex flex-col items-center justify-between hidden">
                        <div className="flex flex-row items-center justify-between m-4">
                            <div className="m-2">
                                <img
                                    src={WhyTibbImageMob1}
                                    className="w-full h-auto mx-auto rounded-lg animate-slideInFromLeft"
                                    alt="whyTibb"
                                />
                            </div>
                            <div className="m-2">
                                <img
                                    src={WhyTibbImageMob2}
                                    className="w-full h-auto mx-auto rounded-lg animate-slideInFromRight"
                                    alt="whyTibb"
                                />
                            </div>
                        </div>
                        <div className="flex flex-row items-center justify-between m-4 mb-12">
                            <div className="m-2">
                                <img
                                    src={WhyTibbImageMob3}
                                    className="w-full h-auto mx-auto rounded-lg animate-slideInFromLeft"
                                    alt="whyTibb"
                                />
                            </div>
                            <div className="m-2">
                                <img
                                    src={WhyTibbImageMob4}
                                    className="w-full h-auto mx-auto rounded-lg animate-slideInFromRight"
                                    alt="whyTibb"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WhyTibb;
