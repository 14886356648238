import React from "react";
// import Image1 from '../assets/images/medical-scribe.png';
// import Image1 from '../assets/images/medical-scribe-2.jpg';
// import Image2 from '../assets/images/tibb-scribe-image-10.png';
// import androidIcon from '../assets/images/android-icon.png';
// import iosIcon from '../assets/images/ios-icon.png';
// import googlePlayIcon from '../assets/images/Google_Play-Icon-Logo.wine.svg';
import Header from "../components/TibbScribe/Header";
import Footer from "../components/TibbScribe/Footer";
import KeyFeatures from "../components/TibbScribe/KeyFeatures";
import WhyTibb from "../components/TibbScribe/WhyTibb";
import DownloadNow from "../components/TibbScribe/DownloadNow";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
// import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import Logo from "../assets/images/logo_tibb_scribe_2.png";
import SubscribeNow from "../components/TibbScribe/SubscribeNow";
import HeroScribe from "../components/TibbScribe/Hero";

const TibbScribe = () => {
    // const handleScroll = (e, targetId) => {
    //     e.preventDefault(); // Prevent default link behavior
    //     const targetElement = document.getElementById(targetId);
    //     if (targetElement) {
    //         targetElement.scrollIntoView({ behavior: "smooth" });
    //     }
    // };

    return (
        <div className="bg-gradient-to-b from-scribe-gradient-start via-scribe-gradient-middle1 to-scribe-gradient-end">
            <Header />
            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 text-primary_text animate-slideInFromAbove">
                <h1 className="text-5xl font-bold mb-4">
                    Tibb <strong className="text-gradient-ai">Scribe</strong>
                </h1>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-0 m-0 rounded-xl text-primary_text"> */}
                {/* <div className="w-1/3"> */}
                    {/* image */}
                    {/* <img
                        src={Image2}
                        className="w-full h-auto mx-auto rounded-lg shadow-lg"
                        alt="Tibb Scribe main"
                    /> */}
                {/* </div> */}
            {/* </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-4 m-0 mb-10 md:p-6 md:m-2 rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-around space-x-4"> */}
                    {/* text on the left */}
                    {/* <div className="w-full md:w-1/2 p-6 rounded-3xl text-center md:text-left"> */}
                        {/* <p className="text-lg text-primary_text animate-slideInFromLeft">
                            Tibb's AI Scribe is a revolutionary solution that harnesses the 
                            power of artificial intelligence to transform the way healthcare providers document 
                            patient encounters. By automatically transcribing clinical conversations 
                            in real-time, Tibb's AI Scribe streamlines the documentation process, 
                            allowing providers to focus more on delivering exceptional patient care.
                        </p> */}
                        {/* <div className="flex flex-col w-full items-center justify-start">
                            <div className="flex w-full md:w-4/5 m-4 items-start animate-slideInFromLeft_F">
                                <img
                                    src={Logo}
                                    className="w-full md:w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                                    alt="Tibb Scribe Logo main"
                                />
                            </div>
                            <div className="flex w-full m-4 items-center text-center justify-center animate-slideInFromLeft">
                                <h1 className="text-xl md:text-3xl text-golden">Focus on Care, Not Charts!</h1>
                            </div>
                            <div className="flex w-full m-2 items-center text-center justify-center animate-slideInFromLeft">
                                <h1 className="text-sm md:text-lg">Let AI Automate Your Notes, Elevate Patient Care <br /> — Secure & HIPAA Compliant.</h1>
                            </div>
                            <div className="flex w-full m-2 justify-center text-2xl text-black animate-slideInFromLeft">
                                <button
                                    type="button"
                                    onClick={(e) => handleScroll(e, "why-tibb")}
                                    className="button-gradient-ai black"
                                >
                                    Explore Now!
                                </button>
                            </div>
                            <div className="flex w-full m-4 justify-center space-x-4 text-2xl text-white animate-slideInFromRight">
                                <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-instaPurple" icon={faInstagram} /></a>
                                <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-facebookBlue" icon={faFacebook} /></a>
                                <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-twitterBlue" icon={faTwitter} /></a>
                                <a href="mailto:contact@tibb.io" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon className="cursor-pointer hover:text-bgWhiteLevel7" icon={faEnvelope} /></a>
                            </div>
                        </div>
                    </div> */}

                    
                    {/* image on the right */}
                    {/* <div className="w-full md:w-1/2 md:text-right pl-5 animate-slideInFromRight hidden md:block">
                        <img
                            src={Image2}
                            className="w-4/5 h-auto mx-auto rounded-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section> */}

            {/* <section id="key-features" className="flex flex-row md:flex-col items-center justify-between p-8 m-8 text-primary_text animate-slideInFromAbove">
                <h1 className="text-3xl font-bold mb-4 animate-slideInFromAbove">
                    Key <strong className="text-gradient-ai">Features</strong>
                </h1>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            <strong className="text-gradient-ai">Real-time</strong> Transcription
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe records provider-patient conversations and transcribes 
                            them instantly into customizable formats, such as SOAP notes or clinical notes.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                        Time-saving <strong className="text-gradient-ai">Efficiency</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            By automating the documentation process, Tibb's AI Scribe significantly 
                            reduces the time providers spend on chart notes, freeing up valuable time 
                            for meaningful interactions with patients.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            <strong className="text-gradient-ai">Customizable</strong> Templates
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe offers a range of pre-designed templates that can be 
                            tailored to suit the specific needs of each practice or provider.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                        <strong className="text-gradient-ai">AI-powered </strong> Coding
                        </h1>
                        <p className="text-lg text-primary_text">
                            Once the chart note is completed, providers can utilize built-in 
                            AI tools to automatically extract relevant CPT codes, ensuring 
                            accurate billing and reimbursement.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            Secure and <strong className="text-gradient-ai">HIPAA-compliant</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe is designed with patient privacy and security in 
                            mind, adhering to strict HIPAA guidelines to protect sensitive 
                            patient information.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                            Data <strong className="text-gradient-ai">Encryption</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            All patient data is encrypted during transmission and storage. This 
                            ensures that even if unauthorized access occurs, the information 
                            remains protected.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            <strong className="text-gradient-ai">Role-Based </strong> Access Control (RBAC)
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe restricts access to patient records based on user roles. 
                            Only authorized healthcare professionals can view or edit patient data. 
                            For example, doctors may have full access, while administrative staff may 
                            have limited access.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                            Audit <strong className="text-gradient-ai">Trails</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe maintains detailed logs of who accessed patient records, when, 
                            and what changes were made. This audit trail helps track any unauthorized 
                            access or suspicious activity.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-4/5 h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                        Time-saving <strong className="text-gradient-ai">Efficiency</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            By automating the documentation process, Tibb's AI Scribe significantly 
                            reduces the time providers spend on chart notes, freeing up valuable time 
                            for meaningful interactions with patients.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            <strong className="text-gradient-ai">Customizable</strong> Templates
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe offers a range of pre-designed templates that can be 
                            tailored to suit the specific needs of each practice or provider.
                        </p>
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                        <strong className="text-gradient-ai">AI-powered </strong> Coding
                        </h1>
                        <p className="text-lg text-primary_text">
                            Once the chart note is completed, providers can utilize built-in 
                            AI tools to automatically extract relevant CPT codes, ensuring 
                            accurate billing and reimbursement.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            Secure and <strong className="text-gradient-ai">HIPAA-compliant</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe is designed with patient privacy and security in 
                            mind, adhering to strict HIPAA guidelines to protect sensitive 
                            patient information.
                        </p>
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                            Data <strong className="text-gradient-ai">Encryption</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            All patient data is encrypted during transmission and storage. This 
                            ensures that even if unauthorized access occurs, the information 
                            remains protected.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left pr-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">            
                        <h1 className="text-3xl font-bold mb-4">
                            <strong className="text-gradient-ai">Role-Based </strong> Access Control (RBAC)
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe restricts access to patient records based on user roles. 
                            Only authorized healthcare professionals can view or edit patient data. 
                            For example, doctors may have full access, while administrative staff may 
                            have limited access.
                        </p>
                    </div>
                </div>
            </section> */}

            {/* <section className="flex flex-row md:flex-col items-center justify-between p-8 m-8 bg-bgNone rounded-xl text-primary_text">
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="w-full md:w-1/2 text-center md:text-left">
                        <h1 className="text-3xl font-bold mb-4">
                            Audit <strong className="text-gradient-ai">Trails</strong>
                        </h1>
                        <p className="text-lg text-primary_text">
                            Tibb's AI Scribe maintains detailed logs of who accessed patient records, when, 
                            and what changes were made. This audit trail helps track any unauthorized 
                            access or suspicious activity.
                        </p>
                    </div>

                    <div className="w-full md:w-1/2 mb-4 md:text-right pl-5">
                        <img
                            src={Image1}
                            className="w-full h-auto mx-auto rounded-lg shadow-lg"
                            alt="Scribing AI"
                        />
                    </div>
                </div>
            </section> */}

            <HeroScribe />
            <WhyTibb />
            <KeyFeatures />
            <SubscribeNow />
            <DownloadNow />

            <Footer />
        </div>
    );
};

export default TibbScribe;
